.spark-btn {
    background-color: #000;
    color: #fff;
    border: #000;
    width: 190px;
    height: 40px;
    font-size: 12px;
    text-transform: uppercase;
}

.signIn {
    background-color: #C4C4C4;
    color: #4C4B5E !important;
    border: 1px solid #000;
    width: 190px;
    height: 40px;
    font-size: 12px;
}

.welcomeMsg {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #4C4B5E;
}

.welcomeDesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.355556px;
    color: #4C4B5E;
}

.signLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    width: 260px;
    height: 26px;
    position: absolute;
    left: calc(50% - 130px);
    top: -7px;
    background-color: #F2F2F9;
}

.straightLine {
    margin: 0 auto;
    width: 450px;
    border: 1px solid #009DDB;
}

.deviceErrorHeadT {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    margin: auto;
    color: #4C4B5E;
    margin-top: 40px
}

.deviceErrorHeadT1 {
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
    margin: auto;
    margin-top: 25px
}

.oops {
    margin: auto;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #FF8C52;
}

#deviceError .modal-content {
    border-radius: 5px 25px;
}

#deviceError .modal-dialog {
    max-width: 70%;
    margin: auto;
    top: 10%
}

.oopsline {
    width: 40px;
    border-bottom: 3px solid #FF8C52;
    height: 3px;
    position: absolute;
    bottom: -20px;
    left: 42%;
}

.browserline{
    width: 38px;
    border-bottom: 3px solid #FF8C52;
    height: 3px;
    position: absolute;
    bottom: -20px;
    left: 48%;
}

.snapline {
    width: 40px;
    border-bottom: 3px solid #23A45D;
    height: 3px;
    position: absolute;
    bottom: -20px;
    left: 48%;
}

.snap {
    margin: auto;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #23A45D;
}
