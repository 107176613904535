.addnewclasshead {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.addnewclasstext {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.maxcharacterlabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #C7C7D4;
}

.continuebtn {
    padding: 13px 30px;
    height: 50px;
    background: #009DDB;
    border-radius: 3px 15px !important;
}

.backbtn {
    padding: 13px 30px;
    height: 50px;
    background: #fff;
    color: #009DDB;
    border: 2px solid #009DDB;
    border-radius: 3px 15px !important;
}

.courseBox {
    border: 2px solid transparent;
    width: 210px;
    height: 150px;
    background: #FFFFFF;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    cursor: pointer;
}

.courseBox:hover {
    border: 2px solid #009DDB;
    cursor: pointer;
}

.courseBox:active {
    border: 2px solid #4C4B5E;
    cursor: pointer;
}

.selectedCourseBox {
    border: 2px solid #4C4B5E;
    cursor: pointer;
}

.courseLabel {
    width: 210px;
    height: 42.46px;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.dateHeaderLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.dateSubHeaderLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.react-calendar {
    font-family: Montserrat !important;
    margin: 0 auto;
    background-color: #F2F2F9 !important;
    padding: 10px 23px;
    width: 372px !important;
}

.react-calendar abbr[data-original-title], .react-calendar abbr[title] {
    text-decoration: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button, .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    font-size: 35px;
}

.react-calendar__navigation__label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.react-calendar__month-view {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
}

.react-calendar__month-view__weekdays {
    border-bottom: 1px solid #4C4B5E;
}

.react-calendar__month-view__weekdays__weekday {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold !important;
    font-size: 9px !important;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
    mix-blend-mode: normal;
    opacity: 0.6;
}

.react-calendar__tile.react-calendar__month-view__days__day {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    color: #9F9FAF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    border-radius: 50%;
    flex-basis: 10% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    border-radius: 50%;
    flex-basis: 10% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
    color: #fff;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
    border-radius: 50%;
    flex-basis: 10% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day abbr {
    color: #fff !important;
}

.react-calendar__tile.react-calendar__month-view__days__day:enabled:hover, .react-calendar__tile.react-calendar__month-view__days__day:enabled:focus {
    border-radius: 50%;
    flex-basis: 10% !important;
    margin-left: 2.15% !important;
    margin-right: 2.15% !important;
}

.className {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
    background: transparent;
}

.backbtn {
    border: 2px solid #009DDB;
    box-sizing: border-box;
    border-radius: 3px 15px;
    background-color: #fff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #009DDB;
    width: 108px;
    height: 50px;
}

.skipBtn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #4C4B5E;
    margin-left: 23px;
    cursor: pointer;
}

.skipBtn:hover {
    text-decoration-line: none;
}

input[type="text"].classname:focus, input[type="text"].classname:active {
    outline: none !important;
    box-shadow: none;
    background: transparent;
}

.classname {
    text-align: center;
    font-size: 36px;
    padding-bottom: 13px;
    height: 55px !important;
    background: transparent;
}

.classname::-webkit-input-placeholder {
    text-align: center !important;
}

.classname:-ms-input-placeholder {
    text-align: center;
}

.importCard {
    width: 210px;
    height: 170px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F9 100%);
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    border: 2px solid #F2F2F9;
}

.importCard:hover {
    border: 2px solid #009DDB;
}

.importCardLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
    margin-top: 22px;
}

.importCardSubLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
    margin-top: 10px;
}

.manualImportForm {
    padding-left: 90px;
    padding-right: 90px;
}

.manualImportForm input[type=text], .manualImportForminput[type=email] {
    width: 280px;
    height: 20px;
    position: relative;
    top: 5px;
    background-color: transparent !important;
}

.manualImportForm input[type=text], .manualImportForm input[type=email] {
    border: 0;
    border-bottom: 2px solid #C7C7D4;
    outline: 0;
    border-radius: 0;
    height: 39px;
}

.manualImportForm label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    /* identical to box height, or 178% */
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #9F9FAF;
}

.manualImportForm .form-group {
    margin-bottom: 30px;
    width: 280px;
    display: inline-block;
    margin-right: 20px;
    position: relative;
}

.manualImportForm .form-group.btn-group {
    margin-bottom: 0px;
    margin-top: 5px;
    width: auto;
    display: inline-block;
    margin-right: 0px;
}

.manualImportForm .form-control:focus, .form-control:active {
    border-color: #009DDB;
    outline: 0;
    box-shadow: none
}

.manualImportForm .addBtn {
    background-color: transparent;
    border: 2px solid #009DDB;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #009DDB;
    width: 82px;
}

.manualImportForm .addBtn:hover {
    background: #009DDB;
    border: 2px solid #009DDB;
    color: #FFFFFF;
}

.manualImportForm .addBtn:active {
    background: #009DDB;
    border: 2px solid #009DDB;
    color: #FFFFFF;
}

.manualImportForm p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #DF2222;
    margin-bottom: 0;
    position: absolute;
    top: auto;
    bottom: -25px;
}

.manualImportForm p::before {
    display: inline;
}

label+p {
    display: none;
}

.manualImportForm input[type=text]:not(:placeholder-shown), .manualImportForm input[type=email]:not(:placeholder-shown) {
    border-color: #23A45D;
}

.manualImportForm p+input[type=text], p+input[type=email], p+input[type=password], p+input[type=search] {
    border-color: #DF2222!important;
}

.manualImportForm .form-control:focus, .manualImportForm .form-control:active {
    border-color: #009DDB;
    outline: 0;
    box-shadow: none
}

.importBox {
    width: 450px;
    height: 180px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F9 100%);
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: auto;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 25px;
    text-align: center;
}

.selectedFileBtn {
    font-size: 14px;
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
    height: 40px;
    background: #fff;
    color: #009DDB;
    border: 2px solid #009DDB;
    border-radius: 50px !important;
}

.selectedFileBtn:hover {
    background: #009DDB;
    border: 2px solid #009DDB;
    box-sizing: border-box;
    color: #fff
}

.fielTypesLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
}

.CSVTab {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    text-decoration-line: underline !important;
    color: #4C4B5E !important;
}

.CSVTab:hover {
    text-decoration-line: none !important;
}

.finishBtn:disabled {
    padding: 13px 30px;
    width: 117px;
    height: 50px;
    left: 605px;
    top: 500px;
    background: #F2F2F9;
    border-radius: 3px 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #9F9FAF;
}

.finishBtn {
    padding: 13px 30px;
    width: 117px;
    height: 50px;
    left: 605px;
    top: 500px;
    background: #009DDB;
    border-radius: 3px 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #fff;
}

.importedFilename {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    border: 1px solid #FFFFFF;
}

.importFileBtn {
    background: #009DDB;
    border: 2px solid #009DDB;
    box-sizing: border-box;
    border-radius: 40px;
    font-size: 14px;
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
    height: 40px;
    color: #fff;
}

.importFileBtn:hover {
    background: #FFFFFF;
    border: 2px solid #009DDB;
    box-sizing: border-box;
    color: #009DDB;
}

.importFileBtn:active {
    background: #FFFFFF;
    border: 2px solid #009DDB;
    box-sizing: border-box;
    color: #009DDB;
}

.deleteBtn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.9px;
    text-decoration-line: underline;
    color: #797979;
    cursor: pointer;
}

.deleteBtn:hover {
    text-decoration-line: none;
}

.studentDetails {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.wronglist {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E !important;
}

.selectedImportCard {
    border: 2px solid #4C4B5E
}

.studentCheckbox {
    width: 24px;
    height: 24px;
    margin-right: 24px;
    position: relative;
    top: 3px;
}

.studentDetails.email {
    width: 253px;
}

.classCodeCard {
    width: 450px;
    height: 230px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F9 100%);
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 0 auto;
    position: relative;
}

.codeLabel {
    height: 73px;
    padding-top: 33px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.classCodeDesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    width: 397px;
    margin: 0 auto;
    margin-top: 10px;
}

.copyPasteLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    text-align: center;
}

.infoIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.infoIcon:hover {
    content: url("../../assets/images/icons/infoPressed.png");
}

.infoIcon:active {
    content: url("../../assets/images/icons/infoPressed.png");
}

.classname::placeholder {
    color: #C7C7D4;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    font-size: 36px;
}

.goToMyClassLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #4C4B5E;
}

.goToMyClassLabel:hover {
    text-decoration-line: none;
}

.goToMyClassLabel:hover>a {
    text-decoration-line: none;
}

#copiedInput {
    width: 120px;
    border: none;
    background: transparent;
    pointer-events: none;
    color: #4C4B5E;
    font-weight: 800;
}

.tooltipDiv {
    position: relative;
    display: inline-block;
}

.tooltipDiv .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipDiv .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltipDiv:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    background: #F94B4B;
    color: #fff;
}

.react-calendar__tile--active {
    background: #F94B4B !important;
    color: #fff;
}

.infoIconsa:hover {
    content: url("../../assets//images//icons/infoPressed.png");
    cursor: pointer;
}

.infoIconsa:active {
    cursor: pointer;
    content: url("../../assets//images//icons/infoPressed.png");
}

#CSVModal .Modaldialog {
    max-width: 900px;
}

#CSVModal .modal-content {
    background: #FFFFFF;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px 25px;
}

.csvHeadText {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #4C4B5E;
}

.csvStep {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #F94B4B;
    margin-top: 10px;
}

.csvTextNormal {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.padding7P {
    padding: 0 7%;
}

.importClassListCard {
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F9 100%);
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 450px;
    min-height: 180px;
    margin: 0 auto;
    padding: 20px;
}

.radio-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px;

    color: #000000 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .sectionCheckmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #C7C7D4;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .sectionCheckmark {
    background-color: #C7C7D4;
  }
  
  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .sectionCheckmark {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .sectionCheckmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .sectionCheckmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radio-container .sectionCheckmark:after {
       top: 8px;
      left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }

  .margin-top-50{
      margin-top: 50px !important;
  }

  .margin-top-15{
    margin-top: 15px !important;
}

.table-bordered td, .table-bordered th{
    vertical-align: middle;
}

.table-bordered tr:first-child td, th {
    border-top: none;
}
.table-bordered tr:last-child td, .table-bordered tr:last-child th{
    border-bottom: none;
}

.table-bordered .no-left-border{
    border-left: none;
}
.table-bordered .no-right-border{
    border-right: none;
}
.table-bordered{
    border: none;
}

.final-btn{
    width: 293px;
    padding: 12px;
    text-decoration: none !important;
    display: inline-block;
}

.final-btn-2{
    padding: 10px;
}

.final-btn-2:hover{
    padding: 12px;
}