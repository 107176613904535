.verticalLine-1 {
    height: 402px;
    width: 2px;
    position: absolute;
    background: linear-gradient(306deg, #FEE04D 2.07%, #FFB850 29.11%, #FF5C55 78.58%, #F94B4B 100%);
}

.verticalLine-2 {
    height: 437px;
    width: 2px;
    position: absolute;
    background: linear-gradient(306deg, #FEE04D 2.07%, #FFB850 29.11%, #FF5C55 78.58%, #F94B4B 100%);
    bottom: -52px;
    right: 185px;
    display: block;
    z-index: 0;
}

.verticalLine-3 {
    height: 386px;
    width: 2px;
    position: absolute;
    background: linear-gradient(306deg, #FEE04D 2.07%, #FFB850 29.11%, #FF5C55 78.58%, #F94B4B 100%);
    bottom: -52px;
    right: 100px;
    display: block;
    z-index: 0;
}

.studentMyClass {
    margin-top: 54px;
    margin-bottom: 55px;
}

.myClassesLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #4C4B5E;
    width: 227px;
}

.myClassesDesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    width: 240px;
    margin-top: 10px;
}

.leftSide {
    position: relative;
    display: block;
    padding-left: 90px;
}

.rightSide {
    position: relative;
    /* display: block; */
    margin-top: 105px;
}

.rightSide>div.innerDiv {
    /* display: inline-flex;
    margin-right: 50px;
    position: relative;
    z-index: 1; */
    padding-right: 50px;
    padding-bottom: 50px;
}

.classCard {
    /* margin-bottom: 55px; */
    width: 240px;
    height: 280px;
    background: #FFFFFF;
    text-align: center;
}

.physicsCard {
    border: 2px solid #F94B4B;
    box-shadow: -6px 7px 0px #F94B4B;
}

.algebraCard {
    border: 2px solid #FF8C52;
    box-shadow: -6px 7px 0px #FF8C52;
}

.historyCard {
    border: 2px solid #FFB850;
    box-shadow: -6px 7px 0px #FFB850;
    position: relative;
    z-index: 1;
}

.classNameLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.goToClassButton {
    border: 2px solid #009DDB;
    box-sizing: border-box;
    border-radius: 40px;
    width: 158px;
    height: 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #009DDB;
    display: flex;
    flex-direction: row;
    padding: 6px 26px;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
}

.goToClassButton:active {
    background: #009DDB;
    color: #FFFFFF;
}

.goToClassButton:hover {
    background: #009DDB;
    color: #FFFFFF;
}

.goToClassButton:active img {
    content: url("../../assets/images/icons/Rightwhite.png");
}

.goToClassButton:hover img {
    content: url("../../assets/images/icons/Rightwhite.png");
}

.goToClassButton img {
    width: 18px;
    height: 14px;
    position: absolute;
    right: 15px;
    top: 11px;
}

.studentCurrentSession {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #9F9FAF;
    /* margin-top: 14px;
    margin-bottom: 17px; */
    min-height: 16px;
}

.codeSecion {
    background: #009DDB;
    text-align: center;
    margin-top: -2px;
    padding-bottom: 82px;
    position: relative;
    z-index: 1;
    padding-top: 60px;
}

.codeSectionElement {
    display: inline-block;
}

.codeSectionDesc {
    padding-bottom: 30px;
    color: #fff;
}

.codeSectionInput {
    position: relative;
    display: flex;
    width: 440px;
    margin: 0 auto;
}

.codeSectionInput>#classCodeInput {
    float: left;
    box-sizing: border-box;
    width: 100%;
    width: 342px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    height: 100px;
    background: transparent;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.525px;
    text-transform: uppercase;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.8;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 3px;
    padding-left: 31px;
    padding-right: 31px;
}

.codeSectionInput>#classCodeInput::placeholder {
    color: #fff;
    opacity: 1;
}

.codeSectionInput>#classCodeInput:-ms-input-placeholder {
    color: #fff;
}

.codeSectionInput>#classCodeInput:focus {
    outline: none;
}

.ClassSreachGoBtn {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.525px;
    text-transform: uppercase;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.8;
    padding: 30px 13px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    cursor: pointer;
}

.ClassSreachGoBtn:hover {
    background: #FFFFFF;
    color: #009DDB;
    cursor: pointer;
    opacity: 1;
}

.ClassSreachGoBtn:active {
    background: #FFFFFF;
    color: #009DDB;
    cursor: pointer;
    opacity: 1;
}

#classCodeInput:focus-within {
    opacity: 1;
}

.errorinclasscode {
    margin-top: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #FFFFFF;
}

input[type=search]::-ms-clear {
    color: red !important;
    background-color: red;
}

.trucateClassNameLabel{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

