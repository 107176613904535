body {
    overflow-x: hidden;
  }
  
  #sidebar-wrapper {
    min-height: calc(100vh - 254px);
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
  }
  
  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
  
  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  
  #page-content-wrapper {
    min-width: 100vw;
    background: linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%);
  }
  
  .fixed-container{
    height: calc(100vh - 254px);
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }
  
  .list-group-flush .list-group-item:first-child{
    border-top-width:1px
  }

  .bg-gray{
    background: linear-gradient(180deg, #EDEDF9 0%, #FFFFFF 100%);
  }

  .btn-outline-warning {
    color: orange;
    border-color: orange;
  }

  .btn-outline-warning:hover {
    color: white !important;
    background-color: orange;
    border-color: orange;
  }

  .select-search{
    width: 100% !important;
  }

  .table td, .table th {
    vertical-align: middle;
  }

  .is-active{
   
    background: #fff !important;
  }
  .btn:disabled{
    cursor: not-allowed !important;
  }

  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }
  
    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }
  
    #wrapper.toggled #sidebar-wrapper {
      margin-left: -15rem;
    }
  }