.slider-control-bottomcenter ul {
    display: none !important;
}

.landingPageContainer {
    padding: 20px 30px 29px 51px;
}

.skipTourBtn {
    border: 2px solid #009DDB;
    box-sizing: border-box;
    border-radius: 40px;
    width: 144px;
    height: 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #009DDB;
}

.carouselButton {
    width: 50px;
    height: 50px;
    background: #000000;
    cursor: pointer;
    border-radius: 50%;
    padding: 13px 18px;
}

.landingPageHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #000000;
}

.landingPageSubHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.355556px;
    color: #000000;
    width: 598px;
    margin: 0 auto;
    margin-top: 25px;
}

.slider-control-centerleft {
    left: -90px !important;
}

.slider-control-centerright {
    right: -90px !important;
}

.slider-control-centerright .carouselButton {
    padding: 13px 21px;
}

.slider {
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.201896);
    border-radius: 5px;
    border: none;
}

.sliderImageContainer{
    display: flex;
}

.sliderImageContainer img{
    margin: auto;
    width: 90%;
}

.mobileWelcome{
    /* display: none !important; */
}