.profileHeader {
    font-weight: 800;
    font-size: 18px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #4C4B5E;
}

.profileSubHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    margin-bottom: 30px;
    margin-top: 13px;
}

.profilePage .spark-btn {
    background-color: #000;
    color: #fff;
    border: #000;
    height: 40px;
    font-size: 12px;
    cursor: pointer;
}

.profilePage .form-group{
    padding: 0;
}

.profilePage input.form-control {
    height: 20px;
    position: relative;
    top: 5px;
    background-color: transparent !important;
}

.profilePage input.form-control, .select-search__value .select-search__input{
    width: 85%;
}

.profilePage input[type=text], input[type=email], input[type=password], input[type=search] {
    border: 0;
    border-bottom: 2px solid #C7C7D4;
    outline: 0;
    border-radius: 0;
    height: 39px;
}
.profilePage input[disabled] {
    background: #e9ecef !important;
}

.profilePage label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    /* identical to box height, or 178% */
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #9F9FAF;
}

.profilePage .form-group {
    margin-bottom: 30px;
    width: 280px;
    display: inline-block;
    /* margin-right: 29px; */
}

.profilePage .custom-select-box {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.355556px;
    color: #4C4B5E;
    background: url() no-repeat right #F2F2F9;
    border-radius: 5px;
    -webkit-appearance: none;
    width: 100%;
    height: 50px;
    font-weight: 600;
    padding: 15px;
    background-position-x: 93%;
}

.profilePage .form-control:focus, .form-control:active {
    border-color: #009DDB;
    outline: 0;
    box-shadow: none
}

.saveChangesBtn {
    border: 2px solid #009DDB;
    box-sizing: border-box;
    border-radius: 40px;
    width: 144px;
    height: 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    background-color: #fff;
    letter-spacing: 0.3px;
    color: #009DDB;
}

.saveChangesBtn:active {
    background: #009DDB;
    color: #FFFFFF;
}

.saveChangesBtn:hover {
    background: #009DDB;
    color: #FFFFFF;
}

.saveChangesBtn:active img {
    content: url("../../assets/images/icons/Rightwhite.png");
}

.saveChangesBtn:hover img {
    content: url("../../assets/images/icons/Rightwhite.png");
}

.saveChangesBtn:disabled {
    border: 2px solid #C7C7D4;
    box-sizing: border-box;
    border-radius: 40px;
    width: 144px;
    height: 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    background-color: #fff;
    letter-spacing: 0.3px;
    color: #C7C7D4;
}

.deleteMyDataBtn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    align-items: center;
    text-align: center;
    letter-spacing: 0.355556px;
    text-decoration-line: underline;
    color: #4C4B5E;
    cursor: pointer;
}


.deleteMyDataBtn:hover {
    text-decoration-line: none;
}

.profilePage .form-group input {
    margin-bottom: 10px;
}

.errMsg {
    position: absolute;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #DF2222;
    width: 280px;
}

.passwordDiv, .stateDistrict, .nameDiv {
    display: inline-block;
}

.select-search input{
    padding-right: 35px;
}