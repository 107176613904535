.sessionTypes{
    display: flex;
    justify-content: space-around;
    padding: 8px 18px;
    text-align: center;
    font-weight: bold;
    font-size: 9px;
    letter-spacing: 0.9px;
    color: #4C4B5E;
    text-transform: uppercase;
    max-width: 210px !important;
    min-width: 210px !important;
}

.muted{
    color: #9F9FAF;
}

@media screen and (max-width: 1024px){
    .sessionTypes{
        font-size: 9px;
    }
}
  