

.mobileWelcomeBack {
    background: linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%);
    padding-bottom: 25px;
}

.mobileWelcomeHead {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
    text-transform: uppercase;
    margin: 40px;
}

.mobileBox {
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F9 100%);
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px 25px;
    padding: 30px 15px;
    margin: 0 15px;
}

.mobileNormalText {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.355556px;
    color: #4C4B5E;
}

.mobileMediumText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    margin:30px 
}

.mobileSmallText {
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
}

@media screen and (max-width: 500px){
    .mobileWelcome{
        display: none;
    }
  }
