.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #C7C7D4;
  border: none;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label.lg  {
  width: 50px;
  height: 25px;
  border-radius: 12px;
}

.react-switch-label.md  {
  width: 40px;
  height: 20px;
  border-radius: 10px;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  transition: 0.2s;
  background: #4C4B5E;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-label.lg .react-switch-button.lg {
  top: 4px;
  left: 4px;
  width: 17.5px;
  height: 17.5px;
  border-radius: 17.5px;
}
.react-switch-label.md .react-switch-button.md {
  top: 3px;
  left: 4px;
  width: 14px;
  height: 14px;
  border-radius: 14px;
}

.react-switch-checkbox.md:checked+.react-switch-label.md .react-switch-button.md {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}

.react-switch-checkbox.lg:checked+.react-switch-label.lg .react-switch-button.lg {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}

.react-switch-label.md:active .react-switch-button.md {
  width: 34px;
}

.react-switch-label.lg:active .react-switch-button.lg {
  width: 44px;
}

.lockSpan > img{
  position: relative;
  top:-16px;
}

.lockSpan.lg .locked{
  width: 20px;
  height: 20px;
}

.lockSpan.lg .unlocked{
  width: 21px;
  height: 20px;
  left: -1px;
}