.changeSession.dropdown-menu.show {
    left: 0 !important;
    border-radius: 3px;
    width: auto;
    padding: 10px;
  }

.popover{
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.201896);
  border-radius: 5px;
  border:none;
  padding:10px;
}

.popover-header{
  border:none;
  background: #FFFFFF;
}
.sessionSave{
  padding:0.25rem 1rem;
  background: #009DDB;
  border-radius: 50px;
  font-weight: bold;
}


.popover .arrow {
    display: none;
}

.changeSession{
  color:#4C4B5E;
  cursor: pointer;
}

@media screen and (max-width: 1200px){
  .changeSession{
      max-width: 150px;
  }
  .changeSession>span{
    max-width: 150px;
  }
}
