.correctBorder {
  border: 2px solid #23A45D;
  box-sizing: border-box;
  border-radius: 5px;
}

.wrongBorder {
  border: 2px solid #DF2222;
  box-sizing: border-box;
  border-radius: 5px;
}

.normalBorder {
  border: 2px solid #C7C7D4;
  box-sizing: border-box;
  border-radius: 5px;
}