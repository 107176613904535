.classSectionName {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #4C4B5E;
    margin-left: 20px;
    vertical-align: middle;
}

.ClassTab {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    cursor: pointer;
}

.ClassTab {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    cursor: pointer;
}

.DisabledClassTab {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #9F9FAF;
    cursor: pointer;
}

.SelectedClassTab {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #009DDB;
    cursor: pointer;
}

.progress-bar {
    background: linear-gradient(90deg, #F94B4B 0%, #FF5C55 21.87%, #FFB850 76.56%, #FEE04D 100%)
}

.classProgressTitle {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
    margin-top: 45px
}

.progressText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.sessionBox {
    padding: 30px;
    background: #FFFFFF;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.orangeSession {
    border: 1px solid #FF5D55;
    box-shadow: 4px 4px #FF5D55;
}

.yellowSession {
    border: 1px solid #FFB850;
    box-shadow: 4px 4px #FFB850;
}

.greySession {
    border: 1px solid #C7C7D4;
    box-shadow: 4px 4px #C7C7D4;
}

.boxSessionName {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C4B5E;
    text-transform: uppercase;
}

.boxUnderlineText:hover {
    text-decoration-line: none;
}

.boxUnderlineText {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #4C4B5E;
}

.boxText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.disabledText {
    color: #C7C7D4;
    text-decoration-line: underline;
    pointer-events: none;
    cursor: none;
}

.milestoneList {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    text-align: start;
    padding-left: 16px
}

.rubricModal .modal-dialog {
    max-width: 900px;
}

.selectedCriteria1 {
    padding: 10px 20px;
    background-color: #F2F2F9;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    margin-right: 10px;
    cursor: pointer;
}

.sessionTabSelected {
    border-bottom: 3px solid #009DDB;
    width: 49px;
    position: absolute;
    bottom: -6px;
    left: 85px;
}

.myProjectTabSelected {
    border-bottom: 3px solid #009DDB;
    width: 49px;
    position: absolute;
    bottom: -6px;
    left: 220px;
}

.socialRow {
    padding-top: 30px;
    padding-bottom: 30px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F9 100%)
}

.boldText16px {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.copyTextNormal {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #4C4B5E;
    cursor: pointer;
}

.boldText18px {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.normalText14px {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.normalText36px {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #4C4B5E;
}

#rubricNotScored .modal-dialog {
    padding: 10px 30px 30px 30px;
}

#rubricScored .modal-dialog {
    padding: 10px 30px 30px 30px;
}

/* 
.boxText>span>span:nth-child(1) {
    position: relative;
    top: 0;
    float: left;
} */

.boxText>span>span:nth-child(2) {
    float: right;
}

.lockNotVisible {
    visibility: hidden;
}

.CircularProgressbar {
    width: 26px !important;
    height: 26px !important;
    float: right;
}

.CircularProgressbar-path {
    stroke: #FF5C55 !important;
}

.courseCoverImg {
    padding: 100px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: bottom;
}

.historyCoverImg {
    background-image: url('../../assets/images/history_1366.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

.algebraCoverImg {
    background-image: url('../../assets/images/algebra_1366.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

.physicsCoverImg {
    background-image: url('../../assets/images/physics_1366.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

.circularProgressBarSpan>span>div, .circularProgressBarSpan>div {
    float: right;
    width: 26px !important;
}

.copyContainer #copiedInput {
    position: absolute;
    z-index: -1;
    color: #fff;
    pointer-events: none;
}

.copyContainer input#copiedInput::selection {
    color: #fff;
    background: #fff;
    text-decoration: none;
}

.sessionProgress{
    text-align: left;
    padding-left: 10px;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.9px;
    font-weight: bold;
    color: #4C4B5E;
}
.assignmentLabel{
    min-width: 131px;
}