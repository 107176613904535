.signin-btn {
    background-color: #000;
    color: #fff;
    border: #000;
    height: 40px;
    width: 180px;
    font-size: 12px;
}

input[type=text], input[type=email], input[type=password] {
    border: 0;
    border-bottom: 2px solid #C7C7D4;
    outline: 0;
    border-radius: 0;
    height: 20px;
}

form label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    /* identical to box height, or 178% */
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #9F9FAF;
}

.signinform .form-control:focus, .signinform .form-control:active {
    border-color: #009DDB;
    outline: 0;
    box-shadow: none
}

.signInMsg {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
}

.signInDesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
}

.signLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
}

.signInBox {
    width: 640px;
    margin: 49px auto 20px auto;
    background-color: #ffffff;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.emailSignInLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    /* width: 260px;
    height: 26px;
    position: absolute; */
    left: calc(50% - 130px);
    top: -7px;
    background-color: #ffffff;
    padding:0 10px;

}


.signin-decoration{
    max-width: 95px;
    width: 100%;
    border-bottom: 1px solid #F2F2F9;
    height: 1px;
}

.forgotPasswordLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.notHaveAcc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}