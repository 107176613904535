.item-row{
  background-color: white;
  width: 100%;
  margin-bottom:15px;
  cursor: pointer;
  border-radius: 5px;
}

.item-content{
  padding: 18px;
  padding-right:30px ;
}

.item-row:hover{
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
}

.item-row:hover .right-arrow .arrow-icon{
  color: #009DDB;
}

.item-row .right-arrow .show{
  color: #009DDB;
}


.progress-background{
  background-color: #F2F2F9;
  height: fit-content;
  position: relative;
}

.progress-bar{
  height: 1.2rem;
  max-width: 100%;
}

.row-vertical-divider{
  position: absolute;
  width: 100%;
  top: 0;
  
}

.row-vertical-divider .col{
  height: 1.2rem;
  border-left: 1px solid;
  border-color: #9F9FAF;
}

.row-vertical-divider .col:last-child{
  border-right: 1px solids;
  border-color: #9F9FAF;
}

.right-arrow{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.right-arrow.active{
  color: #009DDB;
}

.right-arrow .arrow-icon{
  margin-right: 20px;
}

.right-arrow .dropdown-menu {
  left: 0 !important;
  margin-left: 10px;
  margin-top: 3px;  
}

.option-menu{
  position: absolute;
  top: 10px;
  padding-right: 5px;
}

.session-name{
  font-size: 14px;
}

.course-progress{
min-width: 60px;
max-width: 60px;
position: relative;
}

