.sessionMaterialDiv {
    margin: 20px 15px 20px 13px
}

.SM-heading {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C4B5E;
    margin-bottom: 8px;
}

.SM-headtext {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    margin-bottom: 20px;
}

.SM-docs {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #4C4B5E;
}

.SM-viewStandards {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #009DDB;
}

.SM-viewStandards:hover {
    text-decoration-line: none;
}

.SM-viewSession {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #000000;
    cursor: pointer;
}

.SM-viewSession:hover {
    text-decoration-line: none;
}

.SM-downloadAll {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #4C4B5E;
    cursor: pointer;
}

.SM-downloadAll:hover {
    text-decoration-line: none;
}

.SM-filename {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    /* white-space: nowrap; */
    margin-bottom: 8px;
    text-decoration-line: underline;
    cursor: pointer;
}

.SM-filename:hover {
    color: #4C4B5E!important;
    text-decoration-line: none;
}

#standardModal .Modaldialog {
    max-width: 900px;
}

#standardModal .modal-content {
    background: #FFFFFF;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px 25px;
}

.standardHeader {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #4C4B5E;
    margin-bottom: 15px;
    margin-top: 15px;
}

.S-Header-sub {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.S-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.S-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.S-color-header {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    background-image: linear-gradient(10.83deg, #F94B4B 0%, #FF5C55 21.87%, #FFB850 76.56%, #FEE04D 100%);
    color: transparent;
    margin-top: 35px;
    -webkit-background-clip: text;
    margin-bottom: 10px;
}

.S-normal-header {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

#SAQModal .Modaldialog {
    max-width: 980px;
}

#SAQModal .modal-content {
    background: #FFFFFF;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px 25px;
}

.SAQ-head {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.SAQ-question {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.SAQ-optionQ {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: inline;
    align-items: center;
    color: #4C4B5E;
    padding: 0;
}

.SAQ-optiontext {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: inline;
    align-items: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.disabledPage {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #9F9FAF;
    pointer-events: none;
    cursor: pointer;
}

.enabledPage {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #4C4B5E;
    cursor: pointer;
}

.enabledPage:hover {
    color: #009DDB;
    text-decoration-line: none;
}

.SM-fileIcon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.correctAnsColor {
    color: #23A45D
}

.selectedPageBorder {
    border-bottom: 2px solid #009DDB;
}