.resource{
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    color: #4C4B5E;
    letter-spacing: 0.3px;
}

.resource-title{
     font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    
}

.tab-list{
    padding: 25px 0 35px;
    width: fit-content;
}

.resource-subHeading{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin-bottom: 15px;
}

.download-all{
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-decoration-line: underline;
    text-transform: uppercase;
}

.session-items{
    background: #FFFFFF;
    border: 1px solid #979797;
    border-radius: 5px;
    padding: 10px 20px;
    padding-left: 30px;
    margin-bottom: 15px;
}

.session-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    letter-spacing: 0.3px;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
}

.doc-item{
    min-width: 50%;
}

.flex-break{
    flex-basis: 100%;
    height: 0;
}

.session-content{
    display: none;
    margin-top: 15px;
} 
.session-items.active .session-content{
    display: flex;
    flex-direction: column;
}
.session-items:hover, .session-items.active{
    background: linear-gradient(0deg, rgba(242, 242, 249, 0.5), rgba(242, 242, 249, 0.5)), #FFFFFF;
}

.br-1{
    border-right: 1px solid #C7C7D4;
    margin-left: 30px;
    margin-right: 30px;
}