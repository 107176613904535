.spark-SSO-btn {
    background-color: #000;
    color: #fff;
    border: #000;
    width: 100px;
    height: 40px;
    font-size: 12px;
}


