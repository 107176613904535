.forgotPasswordBox {
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.201896);
    border-radius: 5px;
    width: 640px; 
    margin: 50px auto 0px;
}

.forgotpassMsg {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
}

.forgotpassDesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
}

.signLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
}