.__floater__arrow {
    display: none;
}

.__floater__body button[title="Back"] {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 24px !important;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #9F9FAF !important;
    margin-left: 10px !important;
}

.__floater__body button[title="Next"] {
    border: 2px solid #009DDB !important;
    box-sizing: border-box !important;
    border-radius: 40px !important;
    background-color: transparent !important;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: center !important;
    letter-spacing: 0.3px !important;
    color: #009DDB !important;
    width: 73px;
    height: 40px;
    margin-left: 25px;
    padding: 6px !important;
}

.__floater__body button[title="Close"] {
    border: 2px solid #009DDB !important;
    box-sizing: border-box !important;
    border-radius: 40px !important;
    background-color: transparent !important;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: center !important;
    letter-spacing: 0.3px !important;
    color: #009DDB !important;
    padding: 6px !important;
    width: 79px;
    height: 40px;
}

.react-joyride__tooltip>button {
    display: none;
}

.__floater__body {
    width: 400px !important;
    /* max-width: 800px; */
    /* height: 157px !important; */
}

.react-joyride__tooltip {
    padding: 0 !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 25px !important;
    width: unset !important;
}

.react-joyride__tooltip>div:nth-child(1)>div {
    padding: 0 !important;
    font-size: 14px !important;
}

.react-joyride__tooltip>div:nth-child(1) {
    padding: 26px 27px 23px 29px;
    border-top-left-radius: 5px;
    border-top-right-radius: 25px;
}

.react-joyride__tooltip>div:nth-child(2) {
    background: #F2F2F9;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    padding: 10px 26px;
    margin-top: 0 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.startTourLink {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #000000;
    display: inline-block;
    cursor: pointer;
}

.react-joyride__overlay {
    background: linear-gradient(180deg, #3A3F41 0%, #141416 100%) !important;
    mix-blend-mode: multiply !important;
}

.react-joyride__spotlight {
    background-color: #fff !important;
}