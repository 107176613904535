
.confirmationModal .modal-content {
  height: 343px;
  background: #FFFFFF;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px 25px;
}

.confirmationModal .modal-dialog {
  width: 282px;
}

.confirmationModal .headerText {
  margin-top: 17px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #4C4B5E;
}

.confirmationModal .subheaderText {
  margin-top: 30px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #4C4B5E;
}

.confirmationModal .round-filled-btn-small {
  margin-top: 35px;
  background: #009DDB;
  border: 2px solid #009DDB;
  box-sizing: border-box;
  border-radius: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  width: 98px;
}

.confirmationModal .cancel {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  color: #4C4B5E;
  cursor: pointer;
}

.confirmationModal.confirmationBoxHeight .modal-content{
  height:235px;
}

.confirmationModal.confirmationBoxHeight .headerText{
  margin-top: 35px;
}

.confirmationModal.confirmationBoxHeight .round-filled-btn-small{
  margin-top: 15px;
}