.App {
  text-align: center;
}

body {
font-size: 14px;
  font-family: 'Montserrat';
  margin: auto;
  background:  linear-gradient(0deg, rgba(242, 242, 249, 0.5), rgba(242, 242, 249, 0.5)), #FFFFFF;
  z-index: 11;

}

.row {
  margin-left: 0;
  margin-right: 0;
}

.primary-color {
  color: #009DDB;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

button {
  box-shadow: none;
}

button:focus {
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  background-color: #fff;
}

.loaderContainer {
  background-color: #E5E5E5;
  height: 99.8vh;
  position: absolute;
  z-index: 16;
  width: 100%;
  max-width: 1200px;
  margin-top: 2px;
  z-index: 10;
}

.loader {
  margin-top: 35vh;
  text-align: center;
}

.loader--hide {
  display: none;
}

.header {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.201896);
  height: 90px;
  position: relative;
  z-index: 1;
}

.header>div {
  padding-left: 30px;
  padding-top: 15px;
}

.loaderTextContainer {
  position: absolute;
  width: 766px;
  left: calc(50% - 24em);
}

.loaderText, .loaderCleverText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}

.small-round-filled-btn {
  height: 24px;
  left: 568.03px;
  top: 819px;
  background: #009DDB;
  border-radius: 50px;
  color: #fff;
  padding: 4px 13px;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.small-round-filled-btn:hover {
  background: #FFFFFF;
  color: #009DDB;
  border: 2px solid #009DDB;
}

.small-round-filled-btn:active {
  background: #FFFFFF;
  color: #009DDB;
  border: 2px solid #009DDB;
}

li a:focus, select:focus, select>option:focus, select>option:hover {
  outline: none;
}

.select-search {
  width: 280px;
  height: 50px;
  position: relative;
  box-sizing: border-box;
}

.select-search__input:hover {
  border: 2px solid #009DDB !important;
}

.select-search__input:focus-within {
  border: 2px solid #009DDB !important;
}

.select-search ul {
  padding-left: 0;
  margin-bottom: 0;
}

.select-search *, .select-search *::after, .select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */

.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */

.select-search__input {
  display: block;
  height: 50px;
  width: 100%;
  padding: 0 16px;
  border: 1px solid transparent;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.355556px;
  color: #4C4B5E;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACFSURBVHgBhc/BDYAgDAXQAtE5WMWbifHuyTiaR0fg5igyhwa1X01EINgEAm0fKaJt+oU4NrdWxkyWMlHXnS5UOeMsd94OoiuBwh9CL4x0TlZ8sTnsI/TCiLswaKX2mS+al/XHTiFjRiveV2OMfAohL74jfTE9/w29RBEP8wAgloY9xTiHECbf5YeQJGDEtAAAAAElFTkSuQmCC') no-repeat right #F2F2F9;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACFSURBVHgBhc/BDYAgDAXQAtE5WMWbifHuyTiaR0fg5igyhwa1X01EINgEAm0fKaJt+oU4NrdWxkyWMlHXnS5UOeMsd94OoiuBwh9CL4x0TlZ8sTnsI/TCiLswaKX2mS+al/XHTiFjRiveV2OMfAohL74jfTE9/w9RBEP8wAgloY9xTiHECbf5YeQJGDEtAAAAAElFTkSuQmCC');
  border-radius: 5px;
  -webkit-appearance: none;
  width: 100%;
  height: 50px;
  font-weight: 600;
  padding: 15px;
  background-position-x: 93%;
}

.select-search__input:hover {
  border: 1px solid transparent;
}

.select-search__input::-webkit-search-decoration, .select-search__input::-webkit-search-cancel-button, .select-search__input::-webkit-search-results-button, .select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */

.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */

.select-search__options {
  list-style: none;
}

/**
 * Option row
 */

.select-search__row:not(:first-child) {
  border-top: 0px solid #eee;
}

/**
 * Option
 */

.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #F2F2F9;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #009DDB;
  color: #fff;
}

.select-search__option.is-highlighted, .select-search__option:not(.is-selected):hover {
  background: #b3d9ff;
}

.select-search__option.is-highlighted.is-selected, .select-search__option.is-selected:hover {
  background: #009DDB;
  color: #fff;
}

/**
 * Group
 */

.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */

.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACFSURBVHgBhc/BDYAgDAXQAtE5WMWbifHuyTiaR0fg5igyhwa1X01EINgEAm0fKaJt+oU4NrdWxkyWMlHXnS5UOeMsd94OoiuBwh9CL4x0TlZ8sTnsI/TCiLswaKX2mS+al/XHTiFjRiveV2OMfAohL74jfTE9/w29RBEP8wAgloY9xTiHECbf5YeQJGDEtAAAAAElFTkSuQmCC");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */

.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: transparent;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 5;
  top: 51px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

#assessmentModal .modal-dialog {
  max-width: 1100px;
}

#assessmentModal .modal-body {
  padding-right: 30px;
}

.submitBtnAssessment {
  background: #009DDB;
  border: 2px solid #009DDB;
  box-sizing: border-box;
  border-radius: 40px;
  flex: none;
  order: 0;
  align-self: flex-start;
  margin: 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #FFFFFF;
}

.submitBtnAssessment:disabled {
  background: #F2F2F9;
  border: 2px solid #F2F2F9;
  box-sizing: border-box;
  border-radius: 40px;
  flex: none;
  order: 0;
  align-self: flex-start;
  margin: 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #000;
}

.round-filled-btn {
  background: #009DDB;
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  padding: 8px;
}

.round-filled-btn:hover {
  background: #FFFFFF;
  border: 2px solid #009DDB;
  box-sizing: border-box;
  border-radius: 40px;
  color: #009DDB;
}

.round-filled-btn:active {
  background: #FFFFFF;
  border: 2px solid #009DDB;
  box-sizing: border-box;
  border-radius: 40px;
  color: #009DDB;
}

.round-filled-btn:disabled {
  background: #F2F2F9;
  /* Extra Light */
  border: 2px solid #F2F2F9;
  box-sizing: border-box;
  border-radius: 40px;
  color: #9F9FAF;
}

.curve-fill-btn {
  background: #009DDB;
  border-radius: 3px 15px;
  color: #fff;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  padding: 12px 28px;
  border: none;
}

.curve-fill-btn:hover {
  background: linear-gradient(156.68deg, #97E2FF 0%, #009DDB 100%);
  border-radius: 3px 15px;
  color: #FFF;
}

.curve-fill-btn:active {
  background: linear-gradient(156.68deg, #97E2FF 0%, #009DDB 100%);
  border-radius: 3px 15px;
}

.curve-fill-btn:disabled {
  background: white;
  border: 2px solid #C7C7D4;
  box-sizing: border-box;
  border-radius: 3px 15px;
  color: #C7C7D4;
}

.curve-bordered-btn {
  background-color: #fff;
  border: 2px solid #009DDB;
  box-sizing: border-box;
  border-radius: 3px 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #009DDB;
  padding: 10px 26px;
}

.curve-bordered-btn:hover {
  background: linear-gradient(156.32deg, #97E2FF 0%, #009DDB 100%);
  border-radius: 3px 15px;
  color: #FFFFFF;
  border: none;
  padding: 12px 28px;
}

.curve-bordered-btn:active {
  background: linear-gradient(156.32deg, #97E2FF 0%, #009DDB 100%);
  border-radius: 3px 15px;
  color: #FFFFFF;
  border: none;
  padding: 12px 28px;
}

.closeModalPNG:hover {
  width: 33px;
  cursor: pointer;
  height: 32px;
  content: url("./assets//images/icons/pressedCloseModal.png");
}

.closeModalPNG:active {
  cursor: pointer;
  width: 33px;
  height: 32px;
  content: url("./assets//images/icons/pressedCloseModal.png");
}

.underlineAtag {
  cursor: pointer;
  text-decoration: underline !important;
}

.underlineAtag>a:hover {
  text-decoration: none !important;
}

.underlineAtag:active>a {
  text-decoration: none !important;
}

.underlineLabel {
  cursor: pointer;
  text-decoration: underline !important;
}

.underlineLabel:hover {
  text-decoration: none !important;
}

.sortDownicon:hover {
  content: url("./assets/images/icons/sort_down_bordered.png");
}

.sortUpicon:hover {
  content: url("./assets/images/icons/sort_up_bordered.png");
}

.defaultUp:hover {
  content: url("./assets/images/icons/sort_up_bordered.png");
}

.defaultDown:hover {
  content: url("./assets/images/icons/sort_down_bordered.png");
}

.ActiveCardView:active {
  content: url("./assets/images/icons/gridViewPressed.png");
}

.ActiveCardView:hover {
  content: url("./assets/images/icons/gridViewPressed.png");
}

.InactiveCardView:active {
  content: url("./assets/images/icons/gridViewPressed.png");
}

.InactiveCardView:hover {
  content: url("./assets/images/icons/gridViewPressed.png");
}

.ActiveListView:active {
  content: url("./assets/images/icons/listViewPressed.png");
}

.ActiveListView:hover {
  content: url("./assets/images/icons/listViewPressed.png");
}

.InactiveListView:active {
  content: url("./assets/images/icons/listViewPressed.png");
}

.InactiveListView:hover {
  content: url("./assets/images/icons/listViewPressed.png");
}

.InctiveDotsIcon:active {
  content: url("./assets/images/icons/hoverDots.png") !important;
}

.InctiveDotsIcon:hover {
  content: url("./assets/images/icons/hoverDots.png") !important;
}

.hiddenObject {
  position: absolute;
  display: inline;
  top:50%;
  left: 50%;
  
}

.react-calendar__tile--active {
  background: #F94B4B !important;
  color: #FFF !important;
}

.react-calendar__tile--now {
  background: #009DDB !important;
  color: #FFF !important;
}

.progress_progress__SRVrZ {
  float: right;
  display: inline-flex;
  width: 26px !important;
}

.leftside-div {
  margin-left: 37px;
  margin-right: 160px;
}

.sessionTabPad7per {
  padding: 0 7%;
}

.studentContainer {
  background-color: #FFFFFF;
  margin: auto 90px;
  padding-bottom: 50px;
  border-radius: 4px;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
}

.cardicons {
  /* margin: 15px 15px 0 15px; */
  width: 45px;
  height: 45px
}

.welcomefooter {
  padding-top: 33px;
  padding-bottom: 73px
}

.changepasswordBox {
  padding-bottom: 80px
}

.welcomeSliderImg {
  box-sizing: border-box !important;
  display: block!important;
  height: 455px!important;
  position: relative!important;
  width: 780px!important;
  margin: 25px auto!important;
  border: 1px solid rgb(238, 238, 238)!important;
  border-bottom-left-radius: 60px;
  border-top-right-radius: 60px;
}

.welcomeSliderImg:focus {
  outline: none;
}

.projectContainer {
  padding: 0 7%;
}

.sessionSelected {
  width: 40%;
  height: 3px;
  background: #009DDB;
  position: absolute;
  bottom: -7px;
  left: 10%;
}

.projectSelected {
  width: 40%;
  height: 3px;
  background: #009DDB;
  position: absolute;
  bottom: -7px;
  left: 19%;
}

.innerClassSectionBox {
  margin: 7px 10px;
  border: 1px dashed #C4C4C4;
  height: 173px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex
}

.classDetailsContainer {
  background-color: #EDEDF9;
  padding: 30px 20px;
}

.minscreenheight {
  min-height: 75vh;
}

.minscreenheightOut {
  min-height: 85vh;
}

/* all media queries */

/* (1024x768) iPad 1 & 2, Landscape */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (max-device-height: 1366px) {
  .leftside-div {
    margin-left: 37px;
    margin-right: 100px;
  }
  .sessionCard {
    width: 162px !important;
    margin-right: 12px !important;
  }
  .courseProgressDiv {
    padding-left: 10px;
  }
  .rightSide>div.innerDiv {
    display: inline-flex;
    padding-right: 30px;
    position: relative;
  }
  .sessionTabPad7per {
    padding: 0 5%;
  }
  .studentContainer {
    background-color: #FFFFFF;
    margin: auto 35px;
    padding-bottom: 50px;
    border-radius: 4px;
  }
  .cardicons {
    /* margin: 12px 12px 0 12px; */
    width: 36px;
    height: 36px
  }
 
  .classTitle {
    font-size: 25px;
    line-height: 30px;
  }
  .currentSessionLabel {
    font-size: 14px;
  }
  .sessionTabSelected {
    left: 59px !important;
  }
  .myProjectTabSelected {
    left: 193px !important;
  }
  .verticalLine-2 {
    height: 439px;
    width: 2px;
    position: absolute;
    background: linear-gradient(306deg, #FEE04D 2.07%, #FFB850 29.11%, #FF5C55 78.58%, #F94B4B 100%);
    bottom: 2px;
    right: 92px;
    display: block;
    z-index: 0;
  }
  .manualImportForm .form-group {
    width: 23% !important;
    text-align: center;
  }
  .manualImportForm input[type=text], .manualImportForminput[type=email] {
    width: 100% !important;
  }
  .historyCoverImg {
    background-image: url('./assets/images/history_1024.png') !important;
  }
  .algebraCoverImg {
    background-image: url('./assets/images/algebra_1024.png') !important;
  }
  .physicsCoverImg {
    background-image: url('./assets/images/physics_1024.png') !important;
  }
  .mobileViewSessionList {
    display: block;
  }
}

/* (1366x768) WXGA Display */

@media screen and (min-width: 1366px) {
  body {
    max-width: 100% !important;
  }
  .sessionCard {
    margin-right: 56px !important;
  }
  .loaderContainer {
    max-width: 100%;
  }
  .leftSide {
    padding-left: 192px;
  }
  .verticalLine-2 {
    right: 253px;
    bottom: 2px;
  }
  .sessionTabPad7per {
    padding: 0px 11%;
  }
  .projectContainer {
    padding: 0 14%;
  }
  .classSectionContainer {
    padding: 0 5px 50px 5px;
  }
  .largeScreenDiv {
    /* max-width: 1366px !important;
    margin: auto!important; */
  }
}

/* (1920x1080) Full HD Display */

@media screen and (min-width: 1920px) {
  body {
    max-width: 100% !important;
    background-color: #fff;
  }
  .loaderContainer {
    max-width: 100%;
  }
  .welcomeSliderImg {
    box-sizing: border-box!important;
    display: block!important;
    height: 583px !important;
    position: relative!important;
    width: 1000px!important;
    margin: 20px auto!important;
    border: 1px solid rgb(238, 238, 238)!important;
  }
  .leftside-div {
    margin-right: 325px;
  }
  .leftSide {
    padding-left: 225px;
  }
  .verticalLine-2 {
    right: 100px;
    height: 410px;
  }
  .verticalLine-3 {
    right: 300px;
    border: 0px;
    height: 435px;
  }
  .sessionTabPad7per {
    padding: 0px 20%;
  }
  .studentContainer {
    margin: auto 190px;
  }
  .profilePage {
    /* padding: 50px  10px !important; */
  }
  .projectContainer {
    padding: 0 24%;
  }
  .classSectionBox {
    height: 231px;
  }
  .innerClassSectionBox {
    height: 214px;
  }
  .classSectionContainer {
    padding: 0 175px 50px 175px;
  }
  .classDetailsContainer {
    padding: 30px 250px;
  }
  .wordspacing1920 {
    word-spacing: 100px;
  }
  .manualImportForm {
    padding-left: 450px !important;
    padding-right: 450px !important;
  }
  .historyCoverImg {
    background-image: url('./assets/images/history_1920.png') !important;
  }
  .algebraCoverImg {
    background-image: url('./assets/images/algebra_1920.png') !important;
  }
  .physicsCoverImg {
    background-image: url('./assets/images/physics_1920.png') !important;
  }
  .largeScreenDiv {
    /* max-width: 1920px !important;
    margin: auto!important; */
  }
}

@media screen and (max-width: 767px) {
  .straightLine {
    display: none;
  }
  .mobileDiv {
    max-width: 100%;
  }
  .createAccountContainer {
    max-width: 100%;
    box-shadow: none !important;
  }
  .createAccHeader {
    word-spacing: 50px;
  }
  .createAccSubHeader {
    margin-bottom: 0px;
  }
  .mobileViewBtn {
    width: 162px !important;
    margin: 20px 30px;
  }
  .createAccFooter {
    padding: 0 30px;
  }
  .mobileViewFooterlink {
    margin: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 17px;
    padding: 0px!important;
  }
  .mobileViewFooterImg {
    text-align: center!important;
  }
  .footer {
    padding-top: 0px;
  }
  .getStartedBox {
    max-width: 100% !important;
    box-shadow: none!important;
    margin: auto!important;
  }
  .ssoMargin {
    margin-top: 8px;
  }
  .nonMobileCopyright {
    display: none;
  }
  .mobileViewCopyright {
    display: block!important;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .signInBox {
    max-width: 100%;
  }
  .mobileSignINInput {
    margin: 1rem !important;
  }
  .forgotPasswordBox {
    max-width: 100%;
  }
  .getStartedDesc{
    line-height: 26px;
  }
}

.circularProgressBarSpan>span>div>svg>circle {
  transform: rotate(-90deg);
  transform-origin: center;
}

.v-divider{
  border-left: 1px solid  #e9ecef;
}

.h-divider{
  border-bottom: 1px solid  #e9ecef;
}

button.select-search__option{
  height: auto;
  padding: 7px 16px;
}

.mobileWelcome{
display: inline;
}

.sparkButton, .sparkButton:hover{
  background: #009DDB;
  color:#FFFFFF;
  border: 2px solid #009DDB;
  box-sizing: border-box;
  border-radius: 40px;
  letter-spacing: 0.3px;
  padding:4px 15px;
  font-size: 14px;
  line-height: 24px;
}

.text-success{
  color: #23A45D;
  
}

.text-danger{
  color: #DF2222;
}

.text-warning{
  color: #FF8C52;
}

.text-info{
  color: #009DDB;
}

.btn {
  padding: 7px 16px;
  text-transform: uppercase;
  font-family: Montserrat;
}

.btn.disabled{
  color: #575758 !important;
  background-color: #e0e0e6 !important;
  border-color: #e0e0e6;
}

.btn.disabled:hover{
  color: #575758 !important;
  background-color: #bcbcc1 !important;
  border-color: #bcbcc1;
}


.btn-primary{
  background: #009DDB;
  color: white !important;
  border: #009DDB;
}

.btn-primary:hover{
  background: linear-gradient(
    156.32deg
    , #97E2FF 0%, #009DDB 100%);
  border: none;
}

.btn-outline-primary, .btn-outline-primary:focus{
  background-color: transparent !important;
  color: #009DDB !important;
  border:1px solid;
  box-shadow:none;
}

.btn-outline-primary:hover, .btn-outline-primary:active{
  background-color: #0993ca !important; 
  color: #fff !important;
  border-color: #0993ca !important;

}

.btn.btn-outline-secondary{
  background-color: #797979;
  color: white;
  border-radius: 50px;
  padding: 5px 16px;
}

.btn-outlined-light{
  background-color: #C4C4C4;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.btn-outlined-light.disabled{
  background-color: #F5F5F5 ;
  color: #9F9FAF;
}

.btn-pill{
  border-radius: 50px;
  padding-left:16px;
  padding-right:16px;
}


.font-8{
  font-size: 8px !important;
}

.font-9{
  font-size: 9px !important;
}

.font-10{
  font-size: 10px !important;
}

.font-12{
  font-size: 12px !important;
}

.font-14{
  font-size: 14px !important;
}

.font-16{
  font-size: 16px !important;
}

.font-18{
  font-size: 18px !important;
}

.text-underline{
text-decoration: underline;
}

.active .text-underline{
  text-decoration: none;
}

.disabled-link{
  cursor: not-allowed;
  pointer-events: none;
  color: #C7C7D4;
}

.dropdown-menu{
min-width: 120px;
}

.s-card{
  background-color: white;
  width: 100%;
  margin-bottom:15px;
  /* cursor: pointer; */
  border-radius: 5px;
}

.dropdown-header{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #4C4B5E;
}

.dropdown-content{
  font-family: Montserrat;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #4C4B5E;
}

.grid{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 130px);
}

.tab-content{
  min-height: 400px;
}
.hidden {
  display: none;
}

.lsp-09{
  letter-spacing: 0.9px;
  font-weight: bold !important;
}

.lsp-03{
  letter-spacing: 0.3px;
  font-weight: normal !important;
}


.dropdown-button{
  font-weight: bold;
  color: #4C4B5E;
}

.dropwdown-arrow{
  font-size: 30px !important;
  transition: all 0.2s ease !important;
}

.dropdown.show .dropwdown-arrow {
  transform: rotateZ(-180deg);
  
}

.dropwdown-arrow:hover{
color: #009DDB;
}


.close-btn{
  width: 32px;
  height: 32px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  padding: 10px;
  border: 2px solid;
}

.close-btn .MuiSvgIcon-root{
  font-size: 16px;;
}

.no-event{
cursor: default;
pointer-events: none;
}

.confirm-dialog-body{
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #4C4B5E;
}

.btn-dialog-ok{
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.9px;
}

.btn-dialog-cancel, .btn-text{
  font-size: 16px;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  text-transform: none;
  color: #4C4B5E;
}

.btn-text-normal{
  text-transform: none;
}

.input-form-group{
  margin-right: 20px;
}

.input-form-group input{
  background-color: transparent !important;
}

.input-form-group input:disabled, .input-form-group label.disabled {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.38);
  
  opacity: 1;
}

.input-form-group label{
  font-weight: bold;
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #9F9FAF;
}