.iFrameModal .modal-content {
  background: #FFFFFF;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px 25px;
}

.iFrameModal .modal-dialog {
  max-width: 900px;
}

.iFrameModal .headerText {
  margin-top: 17px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #4C4B5E;
}

.iFrameModal .subheaderText {
  margin-top: 30px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #4C4B5E;
}

.iFrameModal .round-filled-btn-small {
  margin-top: 35px;
  background: #009DDB;
  border: 2px solid #009DDB;
  box-sizing: border-box;
  border-radius: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  width: 98px;
}

.iFrameModal .cancel {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  color: #4C4B5E;
  cursor: pointer;
}

.iframeModal {
  height: 110vh;
}

.iframeModal .modal-dialog {
  max-width: initial;
  margin: 0;
  height: 100vh;
}

.iframeModal .modal-body {
  padding: 0;
}

.iframeModal .modal-content {
  border-radius: 0;
  height: 100vh;
  border: none;
}

.iframeModal iframe {
  width: 100%;
  height: 100vh;
}

#SAQModal .Modaldialog {
  max-width: 980px;
}

#SAQModal .modal-content {
  background: #FFFFFF;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px 25px;
}

.SAQ-head {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #4C4B5E;
}

.bottomLine {
  float: left;
  width: 360px;
  border: 2px solid #009DDB;
  position: absolute;
  left: -16px;
  bottom: 0;
}

.SAQ-question {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #4C4B5E;
}

.SAQ-optionQ {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #4C4B5E;
  padding: 0;
}

.SAQ-optiontext {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex !important;
  align-items: center;
  letter-spacing: 0.3px;
  color: #4C4B5E;
}

/* The container */

.containerRadio {
  display: flex;
  position: relative;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.3px!important;
  color: #4C4B5E !important;
}

.radio-button{
  min-width: 24px;
}

.radio-label{
padding-left: 15px;
}
/* Hide the browser's default radio button */

.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 25px;
  width: 25px;
  background-color: #C7C7D4;
  border-radius: 50%;
  margin: auto;
}

/* On mouse-over, add a grey background color */

.containerRadio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.containerRadio input:checked~.checkmark {
  background-color: #009DDB;
}

.selectedOption:focus-within {
  border: 2px solid #009DDB
}

.containerRadio input:checked~.checkmark .centerCircle {
  position: absolute;
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 5px;
  top: 8px;
  left: 8px;
}

.zoomedInImg1 {
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  height: 100% !important;
  width: 140% !important;
  cursor: pointer;
}

.zoomedOutImg {
  height: 425px;
  width: 425px;
  cursor: pointer;
}

.zoomedInImg {
  transform: scale(1.25);
  box-shadow: rgb(0, 157, 219) -4px 4px;
  position: relative;
  left: -30px;
}

.showIcon {
  display: block !important;
}

.hideIcon {
  display: none !important;
}

.disabledSubmitBtn {
  cursor: not-allowed;
  pointer-events: none;
  background: #F2F2F9;
  /* Extra Light */
  border: 2px solid #F2F2F9;
  box-sizing: border-box;
  border-radius: 40px;
  color: #9F9FAF;
}