.navbar.navbar-light {
    background-color: #FFF;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.201896);
    z-index: 11;
}

.navbar-brand {
    padding: 0;
}

.myClass {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: right;
    text-decoration-line: underline;
    color: #000000;
    font-weight: 600;
    padding: 7px 12px 6px 10px;
}

.myClass:hover{
    text-decoration-line: none!important;
}
.myClass:hover>a{
    text-decoration-line: none!important;
}

.addClass {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: right;
    color: #797979;
    font-weight: 600;
    padding: 7px 20px 6px 20px;
    border: 2px solid #797979;
    box-sizing: border-box;
    border-radius: 34px;
    background-color: transparent;
    /* text-transform: uppercase; */
    cursor: pointer;
}
.addClass:hover{
    color: #797979;
text-decoration: none;
}

.addClass img {
    margin-left: 11px;
}

.userDropdown {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    color: #4C4B5E !important;
    font-weight: 600;
    padding: 9px 12px 6px 10px;
}

.userDropdown img {
    margin-left: 20px;
}

.dropdown-toggle::after {
    content: none;
}

.nav-item.dropdown .nav-link.dropdown-toggle img.downIcon {
    display: inline;
}

.nav-item.dropdown .nav-link.dropdown-toggle img.upIcon {
    display: none;
}

.nav-item.dropdown.show .nav-link.dropdown-toggle img.downIcon {
    display: none;
}

.nav-item.dropdown.show .nav-link.dropdown-toggle img.upIcon {
    display: inline;
}

.dropdown-menu.show {
    width: 85px;
    padding: 0;
    right: 0;
    margin: 0;
    left: auto;
    border-radius: 0;
    border:none;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.201896);
    
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #009DDB;
}

.dropdown-item {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    padding: 6px 12px;
}

.addClass a:hover {
    text-decoration: none;
}
@media screen and (max-width: 1200px) {
   .userDropdown>span{ 
        max-width: 250px;
        white-space:normal;
    }
}