.classDashbordHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #4C4B5E;  
  margin-bottom: 8px;
}

.classDashbordSubHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #4C4B5E;
  margin-bottom: 22px;
  
}

.classDashboardDropdown {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #797979;
  width: 200px;
  position: relative;
  cursor: pointer;
}

.dropdown-toggle::after {
  content: none;
}

.nav-item.dropdown .nav-link.dropdown-toggle img.downIcon {
  display: inline;
}

.nav-item.dropdown .nav-link.dropdown-toggle img.upIcon {
  display: none;
}

.nav-item.dropdown.show .nav-link.dropdown-toggle img.downIcon {
  display: none;
}

.nav-item.dropdown.show .nav-link.dropdown-toggle img.upIcon {
  display: inline;
}

.nav-item.dropdown .nav-link.dropdown-toggle img.InctiveDotsIcon {
  display: inline;
  cursor: pointer;
}

.nav-item.dropdown .nav-link.dropdown-toggle img.ActiveDotsIcon {
  display: none;
  cursor: pointer;
}

.nav-item.dropdown.show .nav-link.dropdown-toggle img.InctiveDotsIcon {
  display: none;
  cursor: pointer;
}

.nav-item.dropdown.show .nav-link.dropdown-toggle img.ActiveDotsIcon {
  display: inline;
  cursor: pointer;
}

.dropdown-menu.show {
  left: auto !important;
  right: 5px !important;
  border-radius: 3px;
}

.selected-item {
  background-color: #009DDB;
  color: #fff;
}

.dropdown-menu1.dropdown-menu.show {
  left: 0 !important;
}

.dropdown-menu2.dropdown-menu.show {
  left: 0 !important;
  text-align: center;
}

.py-10 .dropdown-item{
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.dropdown-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.classTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #4C4B5E;
  padding: 5px 15px;
}

.classStarDate {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: #009DDB;
  position: absolute;
  top: 24px;
}

.classSectionBox {
  background: #FFFFFF;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 190px;
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  position: relative;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
}

.classSectionBox:hover {
  border: 2px solid #009DDB;
}

.classSectionBox:hover>div>div>img {
  content: url("../../assets//images//icons/pressedAddBtn.png");
}

.classSectionBox:active {
  border: 2px solid #009DDB;
}

.classSectionBox:active>div>div>img {
  content: url("../../assets//images//icons/pressedAddBtn.png");
}

.dropdown-item:hover {
  background-color: #009DDB;
  color: white;
}

.dropdown-item:focus {
  background-color: #009DDB;
  color: white;
}

.selectedListItem {
  background: #fff
}

.selectedListItem:hover {
  background: #F2F2F9;
}

.selectedListImg:hover>span>img {
  content: url("../../assets//images/icons/pressedAddBtn.png");
}

.selectedListItem:active {
  background: #F2F2F9;
}

.selectedListImg:active>span>img {
  content: url("../../assets//images/icons/pressedAddBtn.png");
}

.downIcon:hover {
  content: url("../../assets/images/icons/DownPressed.png");
}

.upIcon:hover {
  content: url("../../assets/images/icons/UpPressed.png");
}

.trucateClassTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.trucateHorizontalClassTitle {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listViewTitle{
   cursor: pointer;
   -webkit-line-clamp: 1;
}

.viewChanger{
  display: inline-block;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1200px) {
  .leftside-div {
    margin-left: 37px;
    margin-right: 100px;
  }
  .sessionCard {
    width: 162px !important;
    margin-right: 12px !important;
  }
  .courseProgressDiv {
    padding-left: 10px;
  }
  .rightSide>div.innerDiv {
    display: inline-flex;
    padding-right: 30px;
    position: relative;
  }
  .sessionTabPad7per {
    padding: 0 5%;
  }
  .studentContainer {
    background-color: #FFFFFF;
    margin: auto 35px;
    padding-bottom: 50px;
    border-radius: 4px;
  }
  .cardicons {
    width: 36px;
    height: 36px
  }
  
  .classTitle {
    font-size: 25px;
    line-height: 30px;
  }
  .currentSessionLabel {
    font-size: 14px;
  }
  .sessionTabSelected {
    left: 59px !important;
  }
  .myProjectTabSelected {
    left: 193px !important;
  }
  .verticalLine-2 {
    height: 439px;
    width: 2px;
    position: absolute;
    background: linear-gradient(306deg, #FEE04D 2.07%, #FFB850 29.11%, #FF5C55 78.58%, #F94B4B 100%);
    bottom: 2px;
    right: 92px;
    display: block;
    z-index: 0;
  }
  .manualImportForm .form-group {
    width: 23% !important;
    text-align: center;
  }
  .manualImportForm input[type=text], .manualImportForminput[type=email] {
    width: 100% !important;
  }
  .historyCoverImg {
    background-image: url('./../../assets/images/history_1024.png') !important;
  }
  .algebraCoverImg {
    background-image: url('./../../assets/images/algebra_1024.png') !important;
  }
  .physicsCoverImg {
    background-image: url('./../../assets/images/physics_1024.png') !important;
  }
  .mobileViewSessionList {
    display: block;
  }
}

.classTileDropdown{
  padding-right: 10px !important;
  padding-left: 10px !important;
  cursor: pointer;
}

.right-info {
  min-width: 260px;
  max-width: 360px;
  margin-left:22px;
}


.right-info .btn{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 9px !important;
  line-height: 16px !important;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}
.right-info-box{
  padding: 15px 20px;
  background-color: white;
  border-radius: 30px 10px;
  font-family: Montserrat;
}

.right-info-box .title-text{
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  color: #4C4B5E;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.title-text .MuiSvgIcon-root{
  font-size: 30px;
}

.right-info-box .description-text{
  font-size: 14px;
}

.right-info-box.active{
  background:linear-gradient(0deg, rgba(0, 158, 225, 0.2), rgba(0, 158, 225, 0.2)), #FFFFFF;
  border-radius: 10px 30px;
}

.active a.btn.btn-outline-secondary{
  border-color: white;
  border-radius: 50px;
  padding: 5px 16px;
}

.empty-class{
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #4C4B5E;
}

.bottom-mark {
  width: 40px;
  margin: 14px 0;
  border-bottom:2px solid #9F9FAF
}

@media (max-width: 1250px) {
  .right-info {
    max-width: 280px;
  }
}