.forgotpassMsg {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
}

.forgotpassDesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
}

.signLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
}

.minscreenheightResetPass{
    min-height: 75vh;
}

.resetpasswordBox{
    padding: 80px 0;
}