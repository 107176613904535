body {
  overflow-x: hidden;
}

.layout-wrapper{
  position: relative;
}  
.sidebar-wrapper {
    width: 15.375rem;
    min-height: calc(100vh - 90px);
    height: 100%;
    -webkit-transition: width .1s ease-out;
    -moz-transition: width .1s ease-out;
    -o-transition: width .1s ease-out;
    transition: width .1s ease-out; 
    position: absolute;
    background-color: #fff;
    z-index: 10;
}
  
  .sidebar-wrapper.collapsed{
    width: 5.45rem !important;
    
  }
  
  .collapsed .menu-text{
    display: none;
  }
  
  .sidebar-wrapper:hover{
    width: 15.375rem !important;
  }
  
  .sidebar-wrapper:hover .menu-text{
    display: block !important;
  }

  /* .collapsed a.list-group-item:hover{
    width: max-content;
  } */

  .sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
  
  .sidebar-wrapper .list-group {
    padding: 10px;
  }
  
  .page-content-wrapper {
    background: rgba(242, 242, 249, 0.5);
    margin-left:15.375rem;
    width: 100%;
    min-height: calc(100vh - 90px);
  }

  .page-content-wrapper.collapsed{
    margin-left:5.45rem;
  }
  
  .container-fluid{
    padding-left: 22px !important;
    padding-right: 22px !important;
    padding-top: 30px !important;
  }

  .list-group-item{
    border-radius: 30px !important;
  }
  
  .list-group-item-action:focus, .list-group-item-action:hover{
    background: #C7C7D4 !important;    
  }
  
  .is-active{
    color: #4C4B5E;
    background: #F2F2F9 !important;
  }


  .fixed-container{
    height: calc(100vh - 90px);
  }

  .wrapper.toggled .sidebar-wrapper {
    margin-left: 0;
  }
  
  .list-group-flush .list-group-item{
    margin: 5px 0px; 
    border: none;
    padding: 8px 16px;
  }

  .bg-gray{
    background: linear-gradient(180deg, #EDEDF9 0%, #FFFFFF 100%);
  }

  .btn-outline-warning {
    color: orange;
    border-color: orange;
  }

  .btn-outline-warning:hover {
    color: white !important;
    background-color: orange;
    border-color: orange;
  }

  .select-search{
    width: 100% !important;
  }

  .table td, .table th {
    vertical-align: middle;
  }

  .btn:disabled{
    cursor: not-allowed !important;
  }
  .icon-padding{
    padding: 3px;
  }

  .small-text{
    display:block;
    text-align: center;
    text-decoration-line: underline;
    color: #4C4B5E;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 0;
    font-size: 10px;
  }

  .line-clamp{
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-2{
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .class-name-list{
    color: #4C4B5E;
    border-bottom: 1px solid #BDBDBD !important;
    border-radius: 0 !important;
  }
  
  a.class-name-list:hover {
    font-weight: bold;
    color: initial;
  }
  .class-name-list.is-active{
    background-color: transparent !important;
    border: none !important;  
  }

  .class-name-list:last-child{
    border-bottom: none !important;
  }
  
  .pointer{
     cursor: pointer;
  }

  .sidebar-footer{
    font-size: 10px;
  }