.createAccountContainer {
    width: 640px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.201896);
    border-radius: 5px;
    margin: 50px auto 0 auto;
}

.createAccHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #4C4B5E;
    margin-top: 29px;
    margin-bottom: 25px;
}

.createAccSubHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.355556px;
    color: #4C4B5E;
}

.createAccRoleSelection input {
    margin-left: 20px;
    margin-right: 20px;
    width: 20px;
    height: 20px;
    position: relative;
    top: 5px;
}

.createAccRoleSelection {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.spark-btn {
    background-color: #000;
    color: #fff;
    border: #000;
    height: 40px;
    font-size: 12px;
}

input[type=text], input[type=email], input[type=password], input[type=search] {
    border: 0;
    border-bottom: 2px solid #C7C7D4;
    outline: 0;
    border-radius: 0;
    height: 39px;
}

form label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    /* identical to box height, or 178% */
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #9F9FAF;
}

.createAccountForm {
    width: 60%;
    margin: auto;
    margin-top: 31px;
}

.createAccountForm .form-group {
    margin-bottom: 30px;
}

.custom-select-box {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.355556px;
    color: #4C4B5E;
    background: url() no-repeat right #F2F2F9;
    border-radius: 5px;
    -webkit-appearance: none;
    width: 100%;
    height: 50px;
    font-weight: 600;
    padding: 15px;
    background-position-x: 93%;
    border: none;
}

.createAccountForm .form-control:focus, .createAccountForm .form-control:active {
    border-color: #009DDB;
    outline: 0;
    box-shadow: none
}

.createAccountForm p, .createAccRoleSelection span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #DF2222;
}

.createAccountForm p::before, .createAccRoleSelection p::before {
    display: inline;
}

label+p {
    display: none;
}

.createAccountForm p+input[type=text], p+input[type=email], p+input[type=password], p+input[type=search] {
    border-color: #DF2222!important;
}

.createAccountForm .form-control-feedback {
    display: none;
}

.createAccountForm .form-group {
    position: relative;
}

.createAccountForm .form-group p~.form-control-feedback.removeIcon {
    display: inline ;
}

.createAccountForm label~.form-control-feedback {
    top: 25px;
}

.createAccountForm .form-control-feedback.removeIcon, .createAccountForm .form-control-feedback.okIcon {
    width: 18px;
    cursor: pointer;
}

.form-control-feedback {
    position: absolute;
    right: 0;
    z-index: 2;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
}

.createAccFooter {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.createAccFooter>span {
    text-decoration: underline;
}

.createAccFooter>span>a {
    color: #4C4B5E;
}

#policyModal .Modaldialog, #termModal .Modaldialog {
    max-width: 900px;
}

#policyModal .modal-content, #termModal .modal-content, #ageModal .modal-content{
    background: #FFFFFF;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px 25px;
}

/* The container */

.custom-radio-container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    text-transform: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    margin-left: 20px;
}

/* Hide the browser's default radio button */

.custom-radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: #C7C7D4;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.custom-radio-container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.custom-radio-container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.custom-radio-container input:checked~.checkmark:after {
    display: inline-block;
}

/* Style the indicator (dot/circle) */

.custom-radio-container .checkmark:after {
    top: 8.5px;
    left: 8.5px;
    width: 8.5px;
    height: 8.5px;
    border-radius: 50%;
    background: white;
}

.createAccountContainer .select-search {
    width: 100%;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: #C7C7D4;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */

.checkbox-container:hover input~.checkbox-checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.checkbox-container input:checked~.checkbox-checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.checkbox-container input:checked~.checkbox-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.checkbox-container .checkbox-checkmark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.disabledCreateBtn{
    color: #9F9FAF !important;
    background: #F2F2F9 !important;
    pointer-events: none !important;
    cursor:not-allowed;
}

.form-group.createAccRoleSelection span.errorSpan{
    display: inline-block;
    float: left;
    position: relative;
    left: 14%;
}

.ageModal{
    font-style: normal;
    line-height: 24px;
    text-align: center;
    color: #4C4B5E;
}
.modal-dialog.Modaldialog.modal-dialog-centered{
    max-width: 600px;
}
.ageDialogTitle{
    font-weight: 800;
    font-size: 18px;
    letter-spacing: 0.4px;
}

.ageDialogMessage{
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.3px;
}

.ageDialogSeperator{
    border-bottom: 2px solid #009DDB;
    width: 50px;
    margin-bottom: 20px;
}

.sparkButton, .sparkButton:hover{
    background: #009DDB;
    color:#FFFFFF;
    border: 2px solid #009DDB;
    box-sizing: border-box;
    border-radius: 40px;
    letter-spacing: 0.3px;
    padding:4px 15px;
    font-size: 14px;
    line-height: 24px;
}

@media screen and (max-width: 450px){
    .createAccountForm{
        width: 85%;
    }
  }

.password-container{
    top: 20px !important;
    border: 1px solid #009DDB !important;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1) !important;
    padding:0px !important;
    text-transform: uppercase;
    color: #4C4B5E !important;
    font-weight: bold !important;
    font-size: 11px !important;
    line-height: 18px !important;
    letter-spacing: 0.9px !important;
    max-width: 305px !important;
}
.password-container::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #009DDB transparent;
}
.password-container>h3.popover-header{
    font-weight: bold ;
    font-size: 11px ;
    line-height: 18px ;
    letter-spacing: 0.9px;
    padding-bottom: 0;
}
.password-container>div.popover-body{
    color: #4C4B5E !important;
}
.password-container ul {
    list-style: none;
    padding: 0;
    margin:0;
  }
  
  .password-container ul li:before {
    content: '✓';
    padding-right: 15px;
    color: #9F9FAF;
    font-size: 16px;
  }

.disclosure{
    text-align: justify !important;
    margin-top: 20px;
    padding: 0;
    font-size: 10px !important;
    line-height: 1.5rem;
}

.disclosure a{
    font-size: 10px !important;
    line-height: 1.5 rem;
}