.subName {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.custom-select {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.355556px;
    color: #4C4B5E;
    background: url() no-repeat right #F2F2F9;
    border-radius: 5px;
    -webkit-appearance: none;
    width: 282px;
    height: 50px;
    font-weight: 600;
    padding: 15px;
    background-position-x: 93%;
    border: none;
}

.exportgrades {
    font-family: Montserrat;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    color: #4C4B5E !important;
    box-shadow:none !important;
}

.exportgrades span {
    text-decoration-line: underline;
    top: 3px;
    position: relative;
    cursor: pointer;
}

.exportgrades:hover span {
    text-decoration-line: none;
}

.customTabs {
    display: inline-block;
    position: relative;
    margin-right: 55px;
    cursor: pointer;
}

.tabLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    margin-bottom: 5px;
}

.tabLabel.active {
    color: #009DDB;
}

.activeTab.active {
    width: 50px;
    height: 3px;
    background-color: #009DDB;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
}

.customTabs:hover .activeTab{
    width: 50px;
    height: 3px;
    background-color: #009DDB;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
}

.facilitationDiv {
    margin-left: 20px;
    margin-right: 20px;
    margin-right: 0;
}

.Fac-heading {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C4B5E;
    margin-bottom: 8px;
}

.Fac-headtext {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    margin-bottom: 20px;
}

.classCodeText {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.classCodeText span {
    text-transform: uppercase;
}

.classCodeMsg {
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
}

.launch-fill-btn {
    background: #009DDB;
    border-radius: 3px 15px;
    color: #fff !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    height: 42px;
    width: 261px;
}

.launch-fill-btn:hover {
    background: linear-gradient(156.32deg, #97E2FF 0%, #009DDB 100%);
    border-radius: 3px 15px;
    color: #FFFFFF;
    border: none;
}

.launch-fill-btn:active {
    background: linear-gradient(156.32deg, #97E2FF 0%, #009DDB 100%);
    border-radius: 3px 15px;
    color: #FFFFFF;
    border: none;
}

.TabContentHeaderLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C4B5E;
}

.addStudentLable, .syncStudentLable {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #4C4B5E;
    cursor: pointer;
}

.addStudentLable:hover,.syncStudentLable:hover {
    text-decoration: none !important;
}

.addStudentLable:hover>a, .syncStudentLable:hover>a {
    text-decoration: none !important;
}

.addStudentLable>img, .syncStudentLable>img {
    margin-right: 10px;
}

.rosterHeader>div {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    align-items: center;
}

.colName{
display: flex;
align-items: center;
}

.colVal{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rosterBody>div>div.colName, .rosterBody>div>div.colVal {
    display: inline-flex;
    /* position: relative; */
    /* margin-right: 36px; */
    /* font-family: Montserrat; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #000000;
    text-align: center;
}

.colName>div>img {
    cursor: pointer;
}

.rosterHeader>div:nth-child(2), .studentInfo>div:nth-child(2), .projectBody>div:nth-child(2){
    max-width: 190px !important;
    min-width: 150px;
}

.rosterHeader>div:nth-child(3), .studentInfo>div:nth-child(3), .projectBody>div:nth-child(3) {
    max-width: 210px !important;
    min-width: 150px;
}

.rosterHeader>div:nth-child(4), .studentInfo>div:nth-child(4), .projectBody>div:nth-child(4){
    max-width: 210px !important;
    min-width: 150px;
}

.rosterHeader>div:nth-child(5), .studentInfo>div:nth-child(5), .projectBody>div:nth-child(5){
    max-width: 200px !important;
    min-width: 150px;
}

.rosterHeader>div:nth-child(5)>span{
    width: min-content;
}
.rosterHeader>div:nth-child(6), .studentInfo>div:nth-child(6), .projectBody>div:nth-child(6){
    max-width: 200px !important;
    min-width: 150px;
}

.rosterHeader>div>span{
    width: fit-content;
}

.rosterHeader>div>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 15px;
    height: 28px;
}

.rosterHeader>div>div img:nth-child(2) {
    position: relative;
    /* top: -10px; */
}

.rosterBody {
    margin-top: 8px;
}

.rosterBody>div {
    padding: 15px 0;
    border-top: 1.94px solid #F2F2F9;
}

.rosterBody>div:last-child {
    padding-bottom: 15px;
    border-bottom: 1.94px solid #F2F2F9;
}

.roundBorderedBtns {
    display: inline-block;
    border: 2px solid #009DDB;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #009DDB;
    padding: 6px;
    cursor: pointer;
}

.roundBorderedBtns:hover {
    border: 2px solid #009DDB;
    background: #009DDB;
    color: #FFFFFF;
    cursor: pointer;
}

.roundBorderedBtns:active {
    background: #009DDB;
    border: 2px solid #009DDB;
    color: #FFFFFF;
    cursor: pointer;
}

.resetPasswordBtn {
    width: 167px;
    height: 40px;
    margin-right: 40px;
}

.removeStudentBtn {
    border: 2px solid #DF2222;
    color: #DF2222;
    width: 158px;
    height: 40px;
}

.editStudentFormDiv label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #9F9FAF;
}

.editStudentFormDiv input {
    border: 0;
    border-bottom: 2px solid #C7C7D4;
    outline: 0;
    border-radius: 0;
    height: 39px;
    background-color: transparent;
}

.editStudentFormDiv input:focus {
    box-shadow: none;
    background-color: transparent;
}

.editStudentFormDiv .form-group {
    margin-bottom: 30px;
    width: 29%;
    display: inline-block;
    margin-right: 29px;
}

.editStudentDiv {
    display: block;
    text-align: left;
    padding: 0;
}

.editStudentDiv>div {
    padding: 36px 0 29px 41px;
}

.studentInfoDynamicClass {
    margin-left: -12px;
    margin-right: -12px;
    background-color: rgb(242, 242, 249);
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.sessionCard {
    display: inline-block;
    width: 170px;
    height: 190px;
    background: #FFFFFF;
    border: 2px solid #C7C7D4;
    margin-right: 39px;
    box-shadow: -6px 7px 0px #C7C7D4;
    margin-top: 27px;
    margin-bottom: 30px;
}

.sessionCard:nth-child(1) {
    margin-right: 30px;
}

.sessionCardTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C4B5E;
    margin-left: 19px;
    margin-top: 25px;
}

.sessionCardStatus, .sessionCardProgress {
    margin-left: 19px;
}


.sessionCardStatus label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
    margin-top: 9px;
}

.sessionCardStatus>div>span:nth-child(1) {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.sessionCardProgress label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
    margin-top: 19px;
    margin-bottom: 8px;
}

.sessionCardProgress>div>span:nth-child(1) {
    width: 80px;
    height: 16px;
    background: #C7C7D4;
    border-radius: 2px;
    margin-right: 24px;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.sessionCardProgress>div>span:nth-child(1)>span {
    background: linear-gradient(90deg, #F94C4A 0%, #FF5C55 24.21%, #FFB850 73.56%, #FEE04D 100%);
    position: relative;
    z-index: 2;
    height: 16px;
    display: inline-block;
    top: -1px;
}

.sessionCardProgress>div>span:nth-child(2) {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #4C4B5E;
    position: relative;
    top: -5px;
}

.sessionCompleted {
    border: 2px solid #F94B4B;
    box-shadow: -6px 7px 0px #F94B4B;
}

.sessionInProgress {
    border: 2px solid #FFB850;
    box-shadow: -6px 7px 0px #FFB850;
}

.studentInfoDynamicClass {
    margin-left: -12px;
    margin-right: -12px;
    background-color: rgb(242, 242, 249);
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.classCodeTocopy {
    font-style: normal;
    font-weight: 800;
    font-size: 56px;
    line-height: 64px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #4C4B5E;
}

.tooltiptext1 {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltiptext1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltiptext1:hover {
    visibility: visible;
    opacity: 1;
}

.small-black-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.medium-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.currentSession {
    border: 2px solid red;
    border-style: dotted;
    border-radius: 0 0 5px 5px;
    border-top: none;
}

.sessionNameClass {
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #9F9FAF;
}

.milestoneNameClass {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.currentSessionName {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #F94B4B;
}

.assessmentQuestionHeader, .previousAssessmentQuestionHeader {
    background: #4C4B5E;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 32px;
}

.assessmentQuestionHeader>span, .previousAssessmentQuestionHeader>span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.sessionAssessmentDiv, .sessionPreviousAssessmentDiv {
    margin-left: -12px;
    margin-right: -12px;
}

.assessmentQuestionHeader>span:nth-child(1), .previousAssessmentQuestionHeader>span:nth-child(1) {
    margin-right: 40px;
    display: inline-block;
}

.assessmentQuestionBody, .previousAssessmentQuestionBody {
    padding-left: 33px;
    padding-right: 33px;
}

.assessmentQuestionBody>div>span:nth-child(1), .previousAssessmentQuestionBody>div>span:nth-child(1) {
    margin-right: 40px;
    display: inline-block;
}

.assessmentQuestionBody>div, .previousAssessmentQuestionBody>div {
    padding-top: 22px;
    padding-bottom: 22px;
    border-bottom: 2px solid #ffffff;
}

.releaseAssessmentBtn {
    width: 200px;
    height: 40px;
    display: inline-block;
    margin-top: 30px;
    margin-left: 33px;
    margin-bottom: 20px;
}

.scoreLabel {
    display: inline-block;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #000000;
}

.gradebook-headers {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.355556px;
    color: #4C4B5E;
    border-bottom:  1px solid #BDBDBD;
}

.seeAllBtn {
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    margin-top: 22px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.criteria {
    padding: 10px 20px;
    background-color: #C7C7D4;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    margin-right: 10px;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
}

.selectedCriteria {
    padding: 10px 20px;
    background-color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
    margin-right: 10px;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
}

.correctAns {
    width: 24px;
    height: 24px;
    margin-left: 5px;
}

.criteria-rightBorder {
    border-right: 1px solid #C7C7D4
}

.tooltipDiv {
    position: relative;
    display: inline-block;
}

.tooltipDiv .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipDiv .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltipDiv:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.courseProgressBar {
    width: 50px;
    height: 20px;
    background: #C7C7D4;
    border-radius: 2px;
    margin-right: 24px;
    display: inline-block;
    position: relative;
    z-index: 1;
    top: 5px;
    left: 16px;
}

.courseProgressBar>span {
    background: linear-gradient(90deg, #F94C4A 0%, #FF5C55 24.21%, #FFB850 73.56%, #FEE04D 100%);
    position: relative;
    z-index: 2;
    height: 20px;
    display: block;
    top: 0px;
}

.textDecorationUnderline {
    text-decoration: underline;
}

.textDecorationUnderline:hover {
    text-decoration: none;
}

.selectedBackColor {
    background-color: #F2F2F9;
    border: none !important;
    
}

.progressBarExtracCss{
    width: 50px;
    height: 20px;
    background: #C7C7D4;
    border-radius: 2px;
    margin-right: 24px;
    display: inline-flex;
    position: relative;
    z-index: 1;
    top: 5px;
    left: 16px;
}

.trucateText {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 1024px){
    .sessionDropdown>div.select-search{
        max-width: 180px;
    }
  }

  .customSwitchStyle{
    display: flex;
    align-items: center;
  }
  .customSwitchStyle .switch-label{
    padding-left: 6px;    
  }

  .customSwitchStyle>label{
    margin: 0;
  }

  .student-roster-list .row:nth-child(odd) {background: #FFF}
  
  .student-roster-list .row .session:nth-child(odd) { background: rgba(199, 199, 212, 0.1)}
  
    .student-roster-list .row .session:nth-child(even) { background: rgba(199, 199, 212, 0.2)}
    .student-roster-list .row:first-child,
    .student-roster-list .row .session:first-child,
    .student-roster-list .row .session.active{ background: transparent !important}

  .student-dashboard-list .row:nth-child(even) {background: #FFF}
  .student-dashboard-list .row:first-child, .student-dashboard-list .row:nth-child(2) {background: transparent;}
  .student-dashboard-list .row .session:nth-child(odd) { background: rgba(199, 199, 212, 0.1)}
  .student-dashboard-list .row .session:first-child{ background: transparent}
  .student-dashboard-list .row .session:nth-child(even) { background: rgba(199, 199, 212, 0.2)}
  .student-dashboard-list .row .session.active{ background: transparent !important}

  .student-roster-list .row .col.session{
      padding-top: 9px;
      padding-bottom: 9px;
  }
 
  .student-roster-list .row.assessment-header .col-5.session{
    padding-top: 9px;
    padding-bottom: 9px;
  }
  
  .assessment-header .active{
        color: #009DDB;
  }
 
  .assessment-header .session {
    padding-top: 15px !important;
}
  .assessment-answer{
    border-right: 1px solid #DEDEDE;
    padding-top: 9px;
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .assessment-answer:last-child{
    border-right: none;
  }
  .modal-content{
    border-radius: 5px 25px !important;
    border: none;
  }

  .show-graphic-text{
    font-weight: bold;
    color: #4C4B5E;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    text-decoration: underline;
  }

  .session{
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }

  .scoreProject:nth-child(odd) {background: #FFF;}
  
  .b-2{
      border-width: 2px;
  }

.graphics-image{
    width: inherit;
    box-shadow: rgb(0 157 219) -4px 4px;
    border: 2px solid #009DDB;
}

.page-active{
border-bottom: 2px solid #009DDB;
}

.session-materials{
padding: 8px 18px;
letter-spacing: 0.3px;
line-height: 24px;
}

.title-text {
    min-width: 85px;
}

.edit-student-roster{
    background: rgba(242, 242, 249, 0.5);
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
}

.task-container{
    overflow: hidden;
}

.task-gap{
    min-width:  20px !important;
    min-height: 15px;
}

.task-list{
    width: max-content;
}

.task-item{
    display: flex;
    min-width: 65px;
    min-height: 40px;
    border-right:1px solid #C7C7D4;
}

.milestone-flag{
    border-right: 1px solid #F2F2F9;
    padding-top: 7px;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 45px;
  }

.session-progress .col.session{
    background: #F2F2F9 !important;
}
.session-progress .progress-background{
    position: absolute;
}

.session-progress .title{    
    font-weight: bold;
    font-size: 9.5px;
    line-height: 15px;
    letter-spacing: 0.9px;
    text-transform: uppercase;

}

.task-container-header{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.task-title{
    padding-top: 2px;
    padding-bottom: 2px;
}

  .progress-list{
     width: max-content;
     /* min-height: 21px; */
  }

  .progress-item{
    display: flex;
    min-width: 65px;
    border-right:1px solid #C7C7D4;
}

.session-progress .col.show-progress, .show-progress {
    background-color: #FF5D55 !important;  
}
  .milestone-progress{
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 45px;
    width: 100%;
  }
  
  .task-title-list{
    height: 25px;
    padding: 7px 0;
    display: unset;
    text-align: center;
  }
  
  .task-item:last-child, .milestone-flag:last-child, .progress-item:last-child{
      border: none;
  }

  .milestone-nav{
    padding: 0 2px;
    cursor: pointer;
    align-content: center;
    display: grid;
    height: 50px;
    background: #009DDB;
    color: white;
  }

  .milestone-nav:hover{
    background: #4C4B5E;
    color: white;
  }

  .milestone-nav.disabled{
    background: #C7C7D4;
    color:#4C4B5E;
    cursor: auto;
    pointer-events: none;
  }
  
  .tooltiptext{
    font-weight: bold;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    background-color: #4C4B5E;
    color: #fff;
    padding: 10px 12px;
    border-radius: 6px;
  }

.tooltip-bottom  {
    margin-top: 12px;
    width: 220px;
}
.tooltip-top  {
    margin-bottom: 12px;
    width: 180px;
}
.tooltip-bottom::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -9px;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent #4C4B5E transparent;
}

.tooltip-top::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -9px;
    border-width: 9px;
    border-style: solid;
    border-color: #4C4B5E transparent transparent transparent;
}

.m--15{
    margin-left: -15px;
    margin-right: -15px;
}

.roster-name{
    font-family: Montserrat;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    color: #4C4B5E;
    padding-top: 8px;
    padding-bottom: 8px;
}


.course-progress-bar{
    height: 15px !important;
}

.show-more-button{
    text-align: center;
    padding-top: 40px;
    padding-bottom: 25px;
}

.in-active-session{
    max-width: unset !important;
}

.choice-options{
    font-weight: bold;
    letter-spacing: 0.3px;
    font-size: 14px;
    line-height: 24px;
    margin-right: 5px;
}

.choice-options.correct{
    color: #23A45D;
}