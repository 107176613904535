.getStartedMsg {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #4C4B5E;
}

.getStartedDesc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4C4B5E;
    margin-bottom: 0;
}

.signupLabel:hover {
    text-decoration: none;
}

.signupLabel:hover a {
    text-decoration: none;
}

.signupLabel {
    text-decoration: underline;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.getStartedBox {
    text-align: center;
    width: 640px;
    margin: 50px auto 0 auto;
    padding-bottom: 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.201896);
    border-radius: 5px;
}

.getStartedMsgBox {
    background: #F2F2F9;
    border-radius: 5px 5px 0px 0px;
    padding: 50px 0;
    margin: 0;
}

.border-line {
    height: 2px;
    background: #F2F2F9;
}

.alreadyAccMsg {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.ssoMargin {
    margin-top: 39px;
}