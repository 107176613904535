.footer {
    background: linear-gradient(180deg, #EDEDF9 0%, #FFFFFF 100%);
    left: 0;
    right: 0;
    bottom: 0;
    padding: 35px 30px 29px 30px;
    margin: 0;
    position: relative;
    z-index: 2;
}

.footerLink {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-decoration-line: underline!important;
    color: #4C4B5E!important;
}

.footerLink:hover {
    text-decoration-line: none;
    color: #4C4B5E;
    cursor: pointer;
}

.footerCopyright{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 7.2px;
    line-height: 16px;
    text-align: right;
    /* letter-spacing: 0.2px; */
    text-transform: uppercase;
    color: #4C4B5E;
}

.tourHelper{
    text-decoration: underline !important;
}
.tourHelper:hover{
    text-decoration: none !important;
}
.mobileViewCopyright{
    display: none!important;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

.shareProjectDialog .modal-content, .emailSent .modal-content {
    border-radius: 5px 25px;
}

.shareProjectDialog .modal-dialog {
    max-width: 70%;
    margin: auto;
    top: 10%
}

.shareProjectDialogText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;    
}

.shareProjectDialogHeading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #4C4B5E;
}

.shareProjectDialog .form-group input{
    margin-bottom: 10px;
}

.shareProjectDialog input.form-control {
    min-width: 159px;
    padding:4px 12px;
    position: relative;
    top: 5px;
    background-color: transparent !important;
}

.shareProjectDialog input[type=email] {
    border: 0;
    border-bottom: 2px solid #C7C7D4;
    outline: 0;
    border-radius: 0;
    height: 30px;
}

.shareProjectDialog .form-control:focus, .form-control:active {
    border-color: #009DDB;
    outline: 0;
    box-shadow: none
}

#policyModal .Modaldialog,#termModal .Modaldialog {
    max-width: 900px;
}

#policyModal .modal-content, #termModal .modal-content {
    background: #FFFFFF;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px 25px;
}


.text-success-msg{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 40px;
    letter-spacing: 0.675px;
    text-transform: uppercase;
    color: #23A45D;
}

.spark-button-outline{
    display:flex;
    font-family: Montserrat;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #009DDB !important;
    text-decoration-line: none !important;
    align-self: center;
    padding:8px 15px;
    border: 2px solid #009DDB;
    box-sizing: border-box;
    border-radius: 40px;
    
}

.spark-button-outline .icon{
    padding-right:10px;
}

.share-button{
    align-items: center;
    display: flex;
    width: fit-content;
    text-transform: none !important;
}
